/* eslint-disable no-unused-vars */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Typography, Button } from "antd";
import {
  FundTwoTone,
  MessageTwoTone,
  SolutionOutlined,
  FileAddOutlined,
  ShopTwoTone,
  BookTwoTone,
  IdcardTwoTone,
  // AppstoreTwoTone,
  ProfileTwoTone,
  RobotOutlined,
  PieChartTwoTone,
  // FolderOpenTwoTone,
  // TagTwoTone
} from "@ant-design/icons";
import { Menu } from "./elements";
import { useUser } from "providers/user";
import Logo from "./logo4.png";

const { Item } = Menu;
const { Text } = Typography;

const Sidebar = ({ history }) => {
  const { user, logout } = useUser();

  const handleLogout = async () => {
    await logout();
  };

  let routes = (
    <>
      <Item icon={<MessageTwoTone />} key="/chat">
        <Link to="/chat">
          <Text strong style={{ color: "white" }}>
            Chatbot
          </Text>
        </Link>
      </Item>
      <Item icon={<FundTwoTone />} key="/dashboard">
        <Link to="/dashboard">
          <Text strong style={{ color: "white" }}>
            Dashboard
          </Text>
        </Link>
      </Item>
      <Item icon={<BookTwoTone />} key="/tickets">
        <Link to="/tickets">
          <Text strong style={{ color: "white" }}>
            Tickets Soporte
          </Text>
        </Link>
      </Item>
      <Item icon={<ShopTwoTone />} key="/profile">
        <Link to="/profile">
          <Text strong style={{ color: "white" }}>
            Chatbots
          </Text>
        </Link>
      </Item>
      <Item icon={<IdcardTwoTone />} key="/users">
        <Link to="/users">
          <Text strong style={{ color: "white" }}>
            Usuarios
          </Text>
        </Link>
      </Item>
      <Item icon={<PieChartTwoTone />} key="/analytic">
        <Link to="/analytic">
          <Text strong style={{ color: "white" }}>
            Análisis
          </Text>
        </Link>
      </Item>
      {/* 
      <Item icon={<ProfileTwoTone />} key="/tiers">
        <Link to="/tiers">
          <Text strong style={{ color: "white" }}>
            Planes
          </Text>
        </Link>
      </Item>

      <Item icon={<AppstoreTwoTone />} key="/integrations">
        <Link to="/integrations">
          <Text strong style={{ color: "white" }}>
            Integraciones
          </Text>
        </Link>
      </Item>

       <Item icon={<FolderOpenTwoTone />} key="/invoice">
        <Link to="/invoice">
          <Text strong style={{ color: "white" }}>
            Facturación
          </Text>
        </Link>
      </Item>
      <Item icon={<TagTwoTone />} key="/billing">
        <Link to="/billing">
          <Text strong style={{ color: "white" }}>
            Plan/Creditos
          </Text>
        </Link>
      </Item> */}
    </>
  );

  let routesShopifyClient = (
    <>
      <Item icon={<MessageTwoTone />} key="/chat">
        <Link to="/chat">
          <Text strong style={{ color: "white" }}>
            Chatbot
          </Text>
        </Link>
      </Item>
      <Item icon={<FundTwoTone />} key="/dashboard">
        <Link to="/dashboard">
          <Text strong style={{ color: "white" }}>
            Dashboard
          </Text>
        </Link>
      </Item>
      <Item icon={<BookTwoTone />} key="/tickets">
        <Link to="/tickets">
          <Text strong style={{ color: "white" }}>
            Tickets Soporte
          </Text>
        </Link>
      </Item>
      <Item icon={<ShopTwoTone />} key="/profile">
        <Link to="/profile">
          <Text strong style={{ color: "white" }}>
            Chatbots
          </Text>
        </Link>
      </Item>
    </>
  );

  let routesSupport = (
    <>
      <Item icon={<ProfileTwoTone />} key="/support">
        <Link to="/support">
          <Text strong style={{ color: "white" }}>
            Calificar
          </Text>
        </Link>
      </Item>
      <Item icon={<BookTwoTone />} key="/examples">
        <Link to="/examples">
          <Text strong style={{ color: "white" }}>
            Ejemplos
          </Text>
        </Link>
      </Item>
    </>
  );

  let routesAdmin = (
    <>
      <Item icon={<FundTwoTone />} key="/dashboard">
        <Link to="/dashboard">
          <Text strong style={{ color: "white" }}>
            Dashboard
          </Text>
        </Link>
      </Item>
      <Item
        icon={<SolutionOutlined style={{ color: "#ffffff" }} />}
        key="/clientes"
      >
        <Link to="/clientes">
          <Text strong style={{ color: "white" }}>
            Clientes
          </Text>
        </Link>
      </Item>
      <Item
        icon={<FileAddOutlined style={{ color: "#ffffff" }} />}
        key="/facturacion"
      >
        <Link to="/facturacion">
          <Text strong style={{ color: "white" }}>
            Facturacion
          </Text>
        </Link>
      </Item>
      <Item
        icon={<RobotOutlined style={{ color: "#ffffff" }} />}
        key="/chatbots"
      >
        <Link to="/chatbots">
          <Text strong style={{ color: "white" }}>
            Bots Genericos
          </Text>
        </Link>
      </Item>
      {/* <Item icon={<FileAddOutlined />} key="/logs">
        <Link to="/logs">
          <Text strong style={{ color: "white" }}>
            Logs Master
          </Text>
        </Link>
      </Item> */}
      <Item icon={<MessageTwoTone />} key="/shopify-bots">
        <Link to="/shopify-bots">
          <Text strong style={{ color: "white" }}>
            Shopify Bots
          </Text>
        </Link>
      </Item>
      <Item icon={<PieChartTwoTone />} key="/analytic">
        <Link to="/analytic">
          <Text strong style={{ color: "white" }}>
            Análisis
          </Text>
        </Link>
      </Item>
    </>
  );

  const userRoutes = {
    admin: routesAdmin,
    support: routesSupport,
    shopifyClient: routesShopifyClient,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100hv",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Image
          style={{
            display: "block",
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: 20,
          }}
          preview={false}
          height={28}
          src={Logo}
          alt=""
        />

        <Menu
          theme="dark"
          style={{
            marginTop: 40,
            background: "none",
          }}
          defaultSelectedKeys={history.location.pathname.toLowerCase()}
          selectedKeys={history.location.pathname.toLowerCase()}
        >
          {user?.type === "admin"
            ? routesAdmin
            : user?.type === "support"
            ? routesSupport
            : routes}
        </Menu>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "15px 0 ",
        }}
      >
        <Button type="danger" onClick={() => handleLogout()}>
          Cerrar Session
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
